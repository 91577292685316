.Container {
  height: 100vh !important;
  .login-img-container {
    img.login-img {
      object-fit: cover;
      width: 100%;
      display: block;
      height: 100vh;
    }
  }
  .login-container {
    max-width: 315px;
    margin-left: 5rem;
    .logo {
      height: 130px;
      width: 188px;
      margin-bottom: 33px;
    }
    .text-subtitle {
      margin-bottom: 3rem;
    }
  }
}

@media only screen and (max-width: 600px) {
  .Container {
    .login-img-container {
      display: none;
      img.login-img {
        display: none;
      }
    }
    .login-container {
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      .logo {
        margin-bottom: 10px;
      }
      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
      .text-title {
        display: block;
        text-align: center;
      }
      .text-subtitle {
        text-align: center;
        margin-bottom: 2rem;
      }
    }
  }
}
