.content-layout {
  min-height: 100vh;
  .main-content {
    padding-top: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
}

.sidebar-menu-responsive {
  display: none;
  .responsive-icon {
    &:hover {
      cursor: pointer;
    }
  }
  .menu {
    display: none;
  }
}

.hr-header {
  border-bottom: 1px solid #ececec;
}

.account-name {
  font-size: 14px;
  font-weight: bold;
}

.account-mail {
  font-size: 12px;
  color: #ababab;
}
.button-add-new-order{
  padding: 15px 20px !important;
  border-radius: 5 !important;
}

@media only screen and (min-height: 960px) {
  .content-layout {
    min-height: calc(100vh - 185px);
  }
}

@media only screen and (max-width: 768px) {
  .header-container,
  .hr-header {
    display: none;
  }
  .sidebar-container {
    display: block;
    // z-index: 100;
    position: fixed;
    background-color: #fff;

    opacity: 0;
    transition: opacity 200ms, visibility 200ms;
  }
  .sidebar-container.active {
    display: block;
    opacity: 1;
    z-index: 100;
    visibility: visible;
    // position: absolute;
    // position: fixed;
    // z-index: 100;
    // background-color: #fff;
    // transition: visibility .5s ease;
  }
  .content-layout {
    .main-content {
      padding-top: 0 !important;
      padding-left: 1.0rem !important;
    }
  }
  .main-content.active {
    // margin-left: 205px;
    transition: margin-left 0.5s;
  }
  .sidebar-menu-responsive {
    display: block;
    padding: 10px;
    .menu.active {
      display: block;
      padding-left: 30px;
      transition: 0.7s;
    }
  }
  .button-add-new-order{
    padding: 10px 10px !important;
    border-radius: 5px !important;
  }
}
@media only screen and (max-width: 768px) {
  .sidebar-container.active {
    display: block;
  }
  .sidebar-menu-responsive {
    .menu.active {
      display: block;
      padding-left: 30px;
      transition: 0.7s;
    }
  }
}
