.sidebar {
  width: 200px;
  border-right: 1px solid #ececec;
  padding: 5px 0;
  height: 84vh;
  padding-top: 1.5rem;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
    &:active {
      text-decoration: none;
    }
  }
  .nav-item {
    padding: 15px 0;
    &:hover {
      cursor: pointer;
    }
    .title {
      font-weight: 700;
      color: #000000;
    }
    .nav-active {
      color: #1c37be;
    }
  }
}

@media only screen and (max-width: 600px) {
  .sidebar {
    padding-top: 0;
    padding-left: 1rem;
  }
}
