.counter-container {
  height: 100px;
  border-radius: 5px;
  box-shadow: 0px 3px 10px #353a5d1a;
  .icon-container {
    height: 100px;
    padding: 15px;
    background-color: #1c37be;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    .icon {
      font-size: 72px;
      color: #6173d2;
      align-self: center;
    }
  }
  .counter-number {
    font-size: 32px;
    font-weight: bold;
  }
}

.dashboard-content {
  margin-bottom: 3rem !important;
}

@media only screen and (max-width: 600px) {
  .counter-container {
    height: 60px;
    margin-bottom: 1rem !important;
    .icon-container {
      height: 60px;
      padding: 10px;
      .icon {
        font-size: 36px;
      }
    }
    .counter-number {
      font-size: 18px;
    }
  }
  .dashboard-content {
    margin-bottom: 1rem !important;
  }
}
